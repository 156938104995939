const menuData = [
  {
    title: "Dashboard",
    route: "Dashboard",
    icon: "HomeIcon",
    visible: true,
  },
  {
    title: "eCommerce",
    icon: "ShoppingCartIcon",
    visible: true,
    children: [
      {
        title: "Products",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Product list",
            route: "product",
          },
          {
            title: "Product Simple",
            route: "Add Product Simple",
          },
          /*  {
            title: "Product Variable",
            route: "Add Product Variable",
          },*/
        ],
      },
      {
        title: "Categories",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Categories list",
            route: "Categories",
          },
          {
            title: "Add Category",
            route: "Add Category",
          },
        ],
      },
      /* {
        title: "Étiquettes",
        icon: "CircleIcon",
        route: "Etiquette",
      },*/
      /* {
        title: "Marques",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Listes des marques ",
            route: "Marque",
          },
          {
            title: "Nouvelle marque",
            route: "Add Marque",
          },
        ],
      },*/
      /* {
        title: "Attributs",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Add Attribut",
            route: "AddAttributes",
          },
        ],
      },*/
    ],
  },
  {
    title: "Codes Promos",
    icon: "CreditCardIcon",
    visible: true,
    children: [
      {
        title: "Ajout Codes Promos ",
        route: "ListCodesPromos",
      },
    ],
  },
  {
    title: "Utilisateurs",
    icon: "UsersIcon",
    visible: true,
    children: [
      {
        title: "Liste utilisateurs",
        route: "users",
      },
      {
        title: "Nouvel utilisateur",
        route: "AddUser",
      },
    ],
  },
  {
    title: "Commandes",
    icon: "ShoppingBagIcon",
    visible: true,
    children: [
      {
        title: "Liste commandes",
        route: "commandes",
      },
    ],
  },
  {
    title: "Clients",
    icon: "UsersIcon",
    visible: true,
    children: [
      {
        title: "Liste Clients",
        route: "clients",
      },
    ],
  },
  /*  {
    title: "Settings",
    route: "Settings",
    icon: "ShieldIcon",
    visible: true,
  },*/
];

const visibleMenuData = menuData.filter((item) => item.visible === true);

export default visibleMenuData;
